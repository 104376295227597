import React from 'react';
import { connect } from 'react-redux';

import { getPopups, getGalleryOrderById } from '../selectors';

import ErrorBoundary from '../components/ErrorBoundary';
import Gallery from '../components/gallery/Gallery';
import Overlay from '../components/Overlay';
import CreateShop from '../components/shop/Creation/CreateShop';

import createPopup from '../popup-factory';

const GalleryApp = ({ order, popups, create_shop }) => (
  <ErrorBoundary>
    <div style={{ boxSizing: 'border-box' }}>
      <Gallery order={order} showItemCount={false} showComments={false} />
      {popups.map((p, idx) => createPopup(p, idx))}
      <Overlay popups={popups} />
      {create_shop ? <CreateShop /> : null}
    </div>
  </ErrorBoundary>
);

const mapStateToProps = (state, ownProps) => ({
  order: getGalleryOrderById(state, ownProps.params),
  popups: getPopups(state),
  create_shop: state.temp.create_shop
});

export default connect(mapStateToProps)(GalleryApp);
